var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("header",function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('section.changePassword')))]),_c('p',[_vm._v(_vm._s(_vm.$t('description.changePassword')))])]}),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"main text-left pt-4",on:{"submit":function($event){$event.preventDefault();return _vm.onResetPasswordSubmit.apply(null, arguments)}}},[(!_vm.isForce)?_c('b-form-group',{attrs:{"id":"reset-input-1","label":_vm.$t('label.currentPassword'),"label-for":"reset-input-1"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reset-input-1","maxlength":_vm.passwordMaxLength,"type":"password"},model:{value:(_vm.password0),callback:function ($$v) {_vm.password0=$$v},expression:"password0"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-form-group',{attrs:{"id":"reset-input-2","label":_vm.$t('label.newPassword'),"label-for":"reset-input-2"}},[_c('validation-provider',{attrs:{"rules":"required","name":"password1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reset-input-2","maxlength":_vm.passwordMaxLength,"type":"password"},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"reset-input-3","label":_vm.$t('label.confirmedPassword'),"label-for":"reset-input-3"}},[_c('validation-provider',{attrs:{"rules":"required|passwordReInput:@password1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"reset-input-3","maxlength":_vm.passwordMaxLength,"type":"password"},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"padding-top":"16px","text-align":"right"}},[_c('b-button',{attrs:{"type":"submit","variant":"outline-dark","disabled":invalid}},[_vm._v(_vm._s(_vm.$t('button.update')))])],1)],1)]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }