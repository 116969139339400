












































































import Vue from 'vue'
import { mapState } from 'vuex'

type ChangePasswordForm = {
  password0: string
  password1: string
  password2: string
}

export default Vue.extend({
  props: {
    isForce: {
      type: Boolean,
      default: false,
    },
  },
  data(): ChangePasswordForm {
    return {
      password0: '',
      password1: '',
      password2: '',
    }
  },
  computed: {
    ...mapState('auth', ['passwordMaxLength']),
  },
  methods: {
    onResetPasswordSubmit: function () {
      // @ts-ignore
      const { password0, password1, password2 }: ChangePasswordForm = this
      if (this.isForce) {
        this.$emit('onUpdatePasswordSubmit', password1)
      } else {
        this.$emit('onChangePasswordSubmit', {
          currentPassword: password0,
          newPassword: password1,
        })
      }
    },
  },
})
