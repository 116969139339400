









import Vue from 'vue'

import PersonalSetting from '@/components/templates/c/settings/PersonalSettingsView.vue'
import Toast from '@/utils/toast'

export default Vue.extend({
  components: { PersonalSetting },
  layout: 'none',
  methods: {
    onClosePersonalSettings() {
      this.$router.back()
    },
    async onChangePasswordSubmit(passwordInfo: {
      currentPassword: string
      newPassword: string
    }) {
      try {
        const result = await this.$store.dispatch(
          'auth/changePassword',
          passwordInfo
        )
        console.log(result)
        Toast.success(this, { message: 'パスワードを更新しました。' })
        this.$router.back()
      } catch (e: any) {
        console.log(e)
        let message = ''
        if (e && e.code === 'InvalidPasswordException') {
          message = this.$i18n.t('error.invalidPassword')
        } else {
          message = this.$i18n.t('error.failedResetPassword')
        }
        Toast.error(this, { message })
      }
    },
  },
})
