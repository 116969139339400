





















































import Vue from 'vue'
import { mapGetters } from 'vuex'

import SettingChangePassword from '@/components/organisms/c/SettingChangePassword.vue'

/**
 * 個人設定画面
 */
export default Vue.extend({
  components: { SettingChangePassword },
  data() {
    return {
      targetSetting: 'changePassword',
    }
  },
  computed: {
    ...mapGetters('auth', ['isChangePasswordLoading']),
  },
  methods: {
    switchSetting(mode: string) {
      this.targetSetting = mode
    },
    onClosePersonalSettings() {
      /**
       * 画面を閉じるイベント
       */
      this.$emit('onClosePersonalSettings')
    },
    onChangePasswordSubmit(passwordInfo: {
      currentPassword: string
      newPassword: string
    }) {
      /**
       * パスワード変更リクエストイベント
       */
      this.$emit('onChangePasswordSubmit', passwordInfo)
    },
  },
})
